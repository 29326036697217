@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
body {
  background: #f1f2f4;
  margin: 0;
  font-family: "Quicksand", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiContainer-root h1 a {
  text-decoration: none;
  color: black;
}

.Mui-selected {
  background-color: transparent !important;
}

.Mui-selected:hover {
  background-color: rgba(25, 118, 210, 0.12) !important;
}