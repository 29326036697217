.create-table-wrapper{
    background-color: #eee;
    width: 100%;
    min-height: 300px;
    margin-top: 20px;
    border-radius: 10px;
}

.go-button{
    float: right;
    margin-top: 20px;
    margin-bottom: 20px;
}